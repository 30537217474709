<template>
    <section class="anuncios-ver-solicitud ml-4">
        <div class="row mx-0 h-100">
            <div class="col-11 col-sm-11 col-md-12 col-lg-5 col-xl-5">
                <div class="row mt-4">
                    <div class="col-12">
                        <el-breadcrumb separator-class="el-icon-arrow-right" class="f-300">
                            <el-breadcrumb-item :to="{ name: 'anuncios.main' }">Mis anuncios</el-breadcrumb-item>
                            <el-breadcrumb-item>Solicitud</el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                    <div class="col-12 mt-3">
                      <div v-if="(typeof cartelera.imagenes != 'undefined' && cartelera.imagenes.length > 0 )">
                        <el-image ref="refOpenImages" style="width: 100px; height: 100px" :src="soloImg[0]" :preview-src-list="soloImg" class="d-none" />
                        <vueper-slides class="vueperslides--1 cr-pointer" ref="vueperslides1" :touchable="false" fade="fade" :autoplay="false" :bullets="false" @slide="$refs.vueperslides2 && $refs.vueperslides2.goToSlide($event.currentSlide.index, { emit: false })" fixed-height="325px" :arrows="false">
                            <vueper-slide v-for="(slide, i) in cartelera.imagenes" :key="i" :image="slide.imagen" class="prueba-img" @click.native="openModalImg" />
                        </vueper-slides>
                        <vueper-slides
                        class="no-shadow vueperslides--2 mt-3" ref="vueperslides2"
                        @slide="$refs.vueperslides1 && $refs.vueperslides1.goToSlide($event.currentSlide.index, { emit: false })"
                        :visible-slides="cartelera.imagenes.length"
                        fixed-height="87px"
                        :style="cartelera.imagenes.length == 1 ? 'width:87px;' : ''"
                        :bullets="false"
                        :touchable="false"
                        :gap="cartelera.imagenes.length == 1 ? undefined : 2.5"
                        :arrows="false"
                        >
                            <vueper-slide v-for="(slide, i) in cartelera.imagenes" :key="i" :image="slide.imagen" @click.native="$refs.vueperslides2 && $refs.vueperslides2.goToSlide(i)" />
                        </vueper-slides>
                      </div>
                      <img v-else src="/img/no-imagen/noimagenanuncio.svg" alt="" class="obj-cover h-100 w-100">
                    </div>
                </div>
            </div>
            <div class="col-11 col-sm-11 col-md-6 col-lg-4 col-xl-4 pl-0">
                <div class="row mx-0 mt-4">
                    <p class="f-20 col f-500">Información del anuncio</p>
                    <el-tooltip v-if="cartelera.estado != 1" placement="bottom-end" effect="light" :visible-arrow="false" popper-class="editar-residente br-4">
                      <div slot="content" class="tooltip-editar-residente">
                          <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4" @click="editarAnuncio">
                              <p class="f-15">Editar</p>
                          </div>
                          <div v-if="cartelera.estado == 2" class="d-flex editar-opcion py-1 px-2 cr-pointer br-4" @click="modalFinalizarAnuncio">
                              <p class="f-15">Finalizar</p>
                          </div>
                          <div v-if="cartelera.estado == 2" class="d-flex editar-opcion py-1 px-2 cr-pointer br-4" @click="eliminarAnuncio">
                              <p class="f-15">Eliminar</p>
                          </div>
                      </div>
                      <div class="mas-opciones d-middle-center br-4 f-15 cr-pointer">
                          <i class="icon-vertical-points" />
                      </div>
                    </el-tooltip>
                    <p class="f-17 col-12 mt-3 ">{{cartelera.titulo}}</p>
                    <p v-if="cartelera.categoria.dinero" class="f-15 col-12">{{convertMoney(cartelera.dinero)}}</p>
                    <div class="col-auto my-2">
                        <div class="categoria d-middle br-20 px-2 f-12" :style="`background: ${_.get(cartelera.categoria, 'color')}`">
                            <i :class="`${cartelera.categoria.icono} mr-2 op-05`" />
                            <p class="tres-puntos op-05">{{_.get(cartelera.categoria, 'nombre')}}</p>
                        </div>
                    </div>
                    <div class="col-auto my-2 ml-auto">
                        <div v-show="cartelera.estado==1" class="categoria d-middle br-20 px-2 f-12 bg-pendiente">
                            <p class="tres-puntos op-05">Pendiente</p>
                        </div>
                        <div v-show="cartelera.estado==2" class="categoria d-middle br-20 px-2 f-12 bg-activo">
                            <p class="tres-puntos op-05">Aprobado</p>
                        </div>
                        <div v-show="cartelera.estado==3" class="categoria d-middle br-20 px-2 f-12 bg-rechazado">
                            <p class="tres-puntos op-05">Rechazado</p>
                        </div>
                        <div v-show="cartelera.estado==4" class="categoria d-middle br-20 px-2 f-12 bg-rechazado">
                            <p class="tres-puntos op-05">Cancelado Admin</p>
                        </div>
                        <div v-show="cartelera.estado==5" class="categoria d-middle br-20 px-2 f-12 bg-rechazado">
                            <p class="tres-puntos op-05">Cancelado Cliente</p>
                        </div>
                        <div v-show="cartelera.estado==6" class="categoria d-middle br-20 px-2 f-12 bg-finalizado">
                            <p>Vencido</p>
                        </div>
                    </div>
                </div>
                <div v-if="cartelera.estado!=1" class="row mx-0 pl-3">
                    <div class="categoria d-middle br-20 px-2 f-12 bg-db">
                        <p class="tres-puntos op-05">{{ cartelera.vigencia_inicio | helper-fecha('DD MMM') }} - {{cartelera.vigencia_fin | helper-fecha('DD MMM')  }}</p>
                    </div>
                </div>
                <div class="row mx-0 my-2">
                    <div class="col-12 col-md-6">
                        <p class="d-flex d-middle f-14">
                            <i class="icon-update-check f-17 mr-2" />
                            {{_.get(cartelera, 'publicaciones')}} veces publicado
                        </p>
                    </div>
                    <div class="col-12 col-md-6">
                        <p class="d-flex d-middle f-14">
                            <i class="icon-denied f-17 mr-2" />
                            {{_.get(cartelera, 'rechazos')}} Rechazado
                        </p>
                    </div>
                </div>
                <div v-if="!cartelera.propio" class="row mx-0 my-2">
                     <div class="col-12">
                        <div class="d-flex">
                            <div class="tipo-user rounded-circle d-middle-center my-auto" style="background:#80DCFF">
                                <i class="icon-account-outline op-05 f-20" />
                            </div>
                            <div class="tres-puntos ml-2">
                                <p class="f-15 f-500">{{_.get(cartelera.usuario, 'nombre')}}</p>
                                <p class="f-12 tres-puntos">{{_.get(cartelera.usuario, 'vivienda')}}</p>
                            </div>
                        </div>
                     </div>
                </div>
                <div class="row mx-0 mt-3 mb-2">
                    <div class="col-12">
                        <p class="f-15">{{_.get(cartelera, 'descripcion')}}</p>
                    </div>
                </div>
                <div class="row mx-0 mt-3 mb-2">
                    <div class="col-12">
                        <el-link class="text-primary word-break" :href="cartelera.enlace" target="_blank">{{ cartelera.enlace }}</el-link>
                    </div>
                </div>
                <div v-if="!cartelera.propio" class="row mx-0">
                    <div class="col-auto my-2">
                        <div class="categoria d-middle text-white br-20 px-2 py-1 bg-black">
                            <i class="icon-house-outline mr-1 f-14" />
                            <p class="tres-puntos pr-1 f-12">{{_.get(cartelera.vivienda_carteleras, 'carteleras')}} Anuncios de la vivienda</p>
                        </div>
                    </div>
                    <div class="col-auto my-auto">
                        <p class="text-86 f-14 bg-f5 border br-20 px-2">{{_.get(cartelera.vivienda_carteleras, 'activos')}} Activos</p>
                    </div>
                </div>
            </div>
            <!-- contenedores aside según estado del anuncio -->
            <div v-if="cartelera.estado == 1" class="col-11 col-sm-11 col-md-6 col-lg-3 col-xl-3 pr-0 box-shadow-left">
                <ValidationObserver tag="div" class="container-right-side" ref="validacion">
                    <div class="row mx-0 mt-4">
                        <p class="col-12 f-500 f-20">Aprobación del anuncio</p>
                    </div>
                    <div class="row mx-0 mt-3">
                        <div class="col-12">
                            <p class="f-15">Tipo de anuncio</p>
                            <div class="d-flex mt-2">
                                <div class="tipo-user rounded-circle d-middle-center my-auto" :style="`background:${categoriaSelected.color}`">
                                    <i :class="`${categoriaSelected.icono} op-05 f-20`" />
                                </div>
                                <div class="ml-2 flex-fill">
                                    <ValidationProvider v-slot="{errors}" tag="div" rules="required" name="categoría">
                                    <el-select v-model="cartelera.id_categoria" placeholder="Seleccionar tipo" size="small" class="w-100">
                                        <el-option v-for="(categoria,i) in categorias" :key="i" :label="categoria.nombre" :value="categoria.id"> </el-option>
                                    </el-select>
                                    <p class="text-danger f-10">{{ errors[0] }}</p>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 mt-4">
                        <p class="col-12 f-15">Indicar la vigencia del anuncio</p>
                        <div class="col-12 mt-3">
                            <p class="f-12 pl-2 label-inputs text-left">Fecha de inicio</p>
                            <ValidationProvider v-slot="{errors}" tag="div" rules="required" name="fecha">
                                <el-date-picker v-model="cartelera.vigencia_inicio" :picker-options="pickerOptions" type="date" value-format="yyyy-MM-dd" format="dd [de] MMMM yyyy" placeholder="Selecciona la fecha" class="w-100" size="small" /> 
                                <p class="text-danger f-10">{{ errors[0] }}</p>
                            </ValidationProvider>
                        </div>
                        <ValidationProvider v-slot="{errors}" tag="div" rules="required|numeric|min_value:1|max_value:100" name="duracion" class="col-12 mt-3">
                            <p class="f-12 pl-2 label-inputs text-left">Duración (días)</p>
                            <el-input-number type="number" placeholder="Número de días" v-model="duracion_dias" class="w-100" size="small" />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                    <div class="row mx-0 mt-4">
                        <div class="col-auto d-flex">
                            <i :class="`icon-pin mr-2 cr-pointer f-17 ${cartelera.top == 1 ? 'text-blue' : 'text-general'}`" @click="cartelera.top = (cartelera.top == 1) ? 0 : 1" />
                            <p class="f-14">Fijar el anuncio</p>
                        </div>
                        <div class="col-12 d-flex el-checkbox-negro mt-3">
                            <el-checkbox v-model="cartelera.destacado" :true-label="1" :false-label="0">Destacar el anuncio</el-checkbox>
                        </div>
                    </div>
                    <div class="row mx-0 mt-5">
                        <div class="col-12 mb-2">
                            <button class="btn btn-aprobar h-32px f-14 w-100" @click="aprobarAnuncio">
                                <p class="d-flex op-05 d-middle-center">
                                    <i class="icon-confirmado" />
                                    Aprobar
                                </p>
                            </button>
                        </div>
                        <div class="col-6 pr-1">
                            <button class="btn btn-rechazar h-32px f-14 w-100" @click="rechazarAnuncio">
                                <p class="d-flex op-05 d-middle-center">
                                    <i class="icon-denied" />
                                    Rechazar
                                </p>
                            </button>
                        </div>
                        <div class="col-6 pl-1">
                            <button class="btn btn-eliminar h-32px f-14 w-100" @click="eliminarAnuncio">
                                <p class="d-flex op-05 d-middle-center">
                                    <i class="icon-delete-outline" />
                                    Eliminar
                                </p>
                            </button>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
            <div v-if="cartelera.propio == 1 && cartelera.estado == 2" class="col-11 col-sm-11 col-md-6 col-lg-3 col-xl-3 pr-0 box-shadow-left">
                <div class="row mx-0 my-4">
                    <p class="col-12 f-500 f-20">Personas interesadas</p>
                </div>
                <div class="container-right-side custom-scroll overflow-auto" style="height:calc(100vh - 150px);">
                    <div v-for="(interesado,i) in interesados" :key="i" class="row mx-0 mb-3">
                        <div class="col-12">
                            <div class="d-flex d-middle">
                                <div class="wh-32px rounded-circle d-middle-center" style="background:#80DCFF">
                                    <i class="icon-account-outline op-05 f-20" />
                                </div>
                                <div class="tres-puntos ml-2">
                                    <p class="f-15 f-500">{{interesado.nombre || 'No registra'}}</p>
                                    <p class="f-13 tres-puntos">{{interesado.telefono || 'No registra'}}</p>
                                    <p class="f-12 tres-puntos">{{interesado.vivienda || 'No registra'}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-editar-anuncio ref="modalEditarAnuncio" @update="mostrar"/>
        <modal-confirmar ref="modalabrirAprobarAnuncio" titulo="Aprobar anuncio" mensaje="¿Desea aprobar este anuncio?" tamano="modal-sm" adicional="Aprobar" @adicional="aprobar" />
        <modal-rechazar-anuncio ref="modalabrirRechazarAnuncio" @rechazar="rechazar"/>
        <modal-confirmar ref="modalabrirFinalizarAnuncio" titulo="Finalizar anuncio" mensaje="¿Desea finalizar el anuncio?" tamano="modal-sm" adicional="Finalizar" @adicional="finalizarAnuncio"/>
        <modal-eliminar ref="modalabrirEliminarAnuncio" titulo="Eliminar anuncio" mensaje="¿Desea eliminar el anuncio?" tamano="modal-md" class="f-15 text-center" @eliminar="eliminar">
            <p class="text-center w-100"> Desaparecerá del listado de anuncios del residente.</p>
        </modal-eliminar>
    </section>
</template>
<script>
import Carteleras from '~/services/carteleras'
import Categorias from '~/services/carteleras/categorias'
import moment from 'moment'
export default {
    components:{
        modalRechazarAnuncio: () => import('./partials/modalRechazarAnuncio'),
        modalEditarAnuncio: () => import('./partials/modalAprobarAnuncio.vue')
    },
    data(){
        return{
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < moment().subtract(1,'days');
                },
            },
            categorias:[
                {
                    dinero: 0
                }
            ],
            duracion_dias : 1,
            cartelera:{
                dinero: 0,
                categoria: {
                    dinero: 0
                }
            },
            interesados: [],
            soloImg: [],
        }
    },
    computed: {
        categoriaSelected(){
            return this.categorias.find(el=>el.id == this.cartelera.id_categoria)
        }
    },
    created(){
      this.listarCategorias()
      this.mostrar()
    },
    watch:{
      cartelera: {
        handler(val){
            // console.log(moment(val.vigencia_fin).format('YYYY-MM-DD'))
        },
        deep: true
      }
    },
    methods:{
        openModalImg(){
            this.$refs.refOpenImages.$el.childNodes[0].click();
        },
        verAnunciosVivienda(){
            this.$router.push({ name:'anuncios.ver.anuncios.vivienda', params:{id_vivienda:this.cartelera.usuario.id_vivienda} })
        },
        editarAnuncio(){

            // sí el anuncio es de los míos(anuncios de los administradores) lo puedo editar completamente
            // sino solo se pueden editar los datos básicos

            // console.log("🚀 ~ editarAnuncio ~ this.cartelera.propio:", this.cartelera)
            this.$router.push({ name: 'anuncios.crear',params:{id_cartelera: this.cartelera.id} })

            // if(this.cartelera.propio){
            //     this.$router.push({ name: 'anuncios.crear',params:{id_cartelera: this.cartelera.id} })
            // }else{
            //     let cartelera = _.clone(this.cartelera)
            //     this.$refs.modalEditarAnuncio.toggle(cartelera,this.categorias)
            // }
        },
        modalFinalizarAnuncio(){
            this.$refs.modalabrirFinalizarAnuncio.toggle()
        },
        republicarAnuncio(){
            this.$router.push({ name: 'anuncios.crear' })
        },
        aprobarAnuncio(){
            this.$refs.modalabrirAprobarAnuncio.toggle()
        },
        rechazarAnuncio(){
            this.$refs.modalabrirRechazarAnuncio.toggle()
        },
        eliminarAnuncio(){
            this.$refs.modalabrirEliminarAnuncio.toggle()
        },
        async mostrar(){
          try {
            this.loading = true
            const {data} = await Carteleras.mostrar(this.$route.params.id_cartelera)
            this.cartelera = data.data
            data.data.imagenes.forEach(element => {
                this.soloImg.push(element.imagen)
            });
            // condiciones para la información según el estado
            if(data.data.propio){
                this.interesadosF()
            }

            this.loading = false
          } catch (e){
              console.log(e)
              this.loading = false
          }
        },

        async listarCategorias(){
          try {
            this.loading = true
            let params={
              caso: 1
            }
            const {data} = await Categorias.listar(params)
            this.categorias = data.data
            this.loading = false
          } catch (e){
              console.log(e)
              this.loading = false
          }          
        },

        async interesadosF(){
          try {
            this.loading = true            
            const {data} = await Carteleras.interesados(this.$route.params.id_cartelera)
            this.interesados = data.data
            this.loading = false
          } catch (e){
              this.errorCatch(e)
              this.loading = false
          }  
        },

        async aprobar(){
          try {

            let validate = await this.$refs.validacion.validate()

            if(!validate) return this.notificacion('','Por favor complete todos los campos')

            this.cartelera.vigencia_fin = moment(this.cartelera.vigencia_inio).add(this.duracion_dias,'days').format('YYYY-MM-DD')
            this.cartelera.vigencia_inicio = moment(this.cartelera.vigencia_inicio).format('YYYY-MM-DD')
            this.loading = true
            let payload={
              id_cartelera : this.cartelera.id,
              id_categoria: this.cartelera.id_categoria,
              destacado : this.cartelera.destacado,
              top : this.cartelera.top,
              vigencia_inicio : this.cartelera.vigencia_inicio,
              vigencia_fin : this.cartelera.vigencia_fin
            }
            const {data} = await Carteleras.aprobar(payload)
            if(data.success){
              this.$notify({
                title: 'Aprobar Cartelera',
                message: data.mensaje,
                type: 'success'
              });
              this.$router.push({name:"anuncios.solicitudes"})
            }
            this.loading = false
          } catch (e){
              console.log(e)
              this.$notify({
                title: 'Aprobar Cartelera',
                message: 'Error guardando',
                type: 'warning'
              });
              this.loading = false
          }          
        },

        async rechazar(comentario){
            try {
                let params = {
                    id_cartelera: this.cartelera.id,
                    rechazo_comentario: comentario
                }

                const { data } = await Carteleras.rechazar(params)

                this.notificacion('','Anuncio rechazado correctamete','success')

                this.$router.replace({name: "anuncios.solicitudes"})


            } catch (error) {
                this.errorCatch(error)
            }
        },

        async finalizarAnuncio(){
            try {
                let params = {id_cartelera: this.cartelera.id};
                const { data } = await Carteleras.finalizarAnuncio(params)

                this.notificacion('','Anuncio finalizado correctamente','success')
                this.$router.replace({name: "anuncios.solicitudes"})
            } catch (error) {
                this.errorCatch(error)
            }
        },

        async eliminar(){
            try {
                let params ={ id_cartelera: this.cartelera.id }
                const { data } = Carteleras.eliminar({params})

                this.notificacion('','Anuncio eliminado correctamente','success')
                this.$refs.modalabrirEliminarAnuncio.toggle()
                this.$router.replace({name: "anuncios.solicitudes"})

            } catch (error) {
                this.errorCatch(error)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.anuncios-ver-solicitud{
    height: calc(100vh - 55px);
    
    .vueperslides--2{
        .vueperslide{
            cursor: pointer;
        }
        .vueperslide--active{
            border: 2px solid #109881;
            border-radius: 4px;
        }
    }
    .vueperslide, .vueperslides__parallax-wrapper{
        border-radius: 4px !important;
    }
    .categoria{
        min-height: 22px;
    }
    .tipo-user{
        max-height: 32px;
        min-height: 32px;
        max-width: 32px;
        min-width: 32px;
    }
    .box-shadow-left{
        box-shadow: -4px 0px 6px #00000014;
    }
    .container-right-side{
        // background: #fff 0% 0% no-repeat padding-box; box-shadow: 0px 3px 6px #00000014; 
        // border: 1px solid #dbdbdb; 
        height: calc(100vh - 83px); 
        position: relative;
        .icon-pin::before{
            margin-left: 0px;
        }
        .btn-{
            &aprobar{
                background: #93EDCF;
            }
            &rechazar{
                background: #FFD54F;
            }
            &eliminar{
                background: #FFB2C2;
            }
        }
    }
}
</style>